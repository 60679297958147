import { Link } from 'gatsby'
import React from 'react'

import Logo from './image.jpg'

const Sidebar = ({ siteMetadata }) => (
  <>

    <aside className="sidebar">
      <header>
        <div className="about">
          <div  >
            <Link alt="home" to="/">
              <img src={Logo} alt="photo" />
            </Link>
          </div>
          <div className="author-name">Olga Kudrina</div>
        
        
        </div>
      </header>
      <footer>
        <section className="contact">
         
          <ul>
      
            
        
              
            
              <li>
                <a
                  rel="noreferrer"
                  href={`https://linkedin.com/in/olgakudrina`}
                  target="_blank"
                >
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </a>
              </li>
         
            
              <li>
                <a href={`mailto:hello@olgakudrina.com`} target="_blank">
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                </a>
              </li>
           
          </ul>
        </section>

      </footer>
    </aside>
  </>
)

export default Sidebar
